import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../pages"),
        meta: {
          title: "Início",
          caption: "Página Inicial",
          icon: "home",
          onlyAdministrator: false,
          showHome: false,
          showDrawer: true
        }
      },
      {
        path: "users",
        name: "users",
        component: () => import("../pages/userRegistration"),
        meta: {
          title: "Usuarios",
          caption: "Cadastro de usuarios",
          icon: "group",
          //Apenas Wesley, Felipe, Graccho e Danillo são admins (23.04.2021)
          onlyAdministrator: true,
          showHome: false,
          showDrawer: true
        }
      },
      {
        path: "requests",
        name: "requests",
        component: () => import("../pages/approvalOfPurchaseRequests"),
        meta: {
          title: "Solicitações de Compra",
          caption: "Aprovação Solicitações de Compra",
          icon: "grading",
          onlyAdministrator: false,
          showHome: true,
          showDrawer: false
        }
      },
      {
        path: "requests/requestdetail/:requestId",
        name: "requestDetail",
        component: () =>
          import("../pages/approvalOfPurchaseRequests/requestDetail.vue")
      },
      {
        path: "history",
        name: "history",
        component: () => import("../pages/historyOfPurchaseOrders"),
        meta: {
          title: "Históricos",
          caption: "Histórico dos Pedidos de Compra",
          icon: "history",
          onlyAdministrator: false,
          showHome: false,
          showDrawer: false
        }
      },
      {
        path: "history/historydetail/:orderId",
        name: "historyDetail",
        component: () =>
          import("../pages/historyOfPurchaseOrders/historyDetail.vue")
      },
      {
        path: "orders",
        name: "orders",
        component: () => import("../pages/approvalOfPurchaseOrders"),
        meta: {
          title: "Pedidos",
          caption: "Aprovação Pedidos de Compra",
          icon: "mdi-ballot-outline",
          onlyAdministrator: false,
          showHome: true,
          showDrawer: false
        }
      },
      {
        path: "orders/orderdetail/:orderId",
        name: "orderDetail",
        component: () =>
          import("../pages/approvalOfPurchaseOrders/orderDetail.vue")
      },
      {
        path: "payments",
        name: "payments",
        component: () => import("../pages/approvalOfPayments"),
        meta: {
          title: "Pagamentos",
          caption: "Aprovação Pagamentos",
          icon: "mdi-cash-multiple",
          onlyAdministrator: true,
          showHome: true,
          showDrawer: false
        }
      },
      {
        path: "payments/paymentsDetail/:paymentsId",
        name: "paymentsDetail",
        component: () =>
          import("../pages/approvalOfPayments/paymentsDetail.vue")
      },
      {
        path: "warehouse",
        name: "warehouse",
        component: () => import("../pages/approvalOfWarehouseRequests"),
        meta: {
          title: "Solicitações ao Armazém",
          caption: "Aprovação Solicitações ao Armazém",
          icon: "mdi-warehouse",
          onlyAdministrator: false,
          showHome: true,
          showDrawer: false
        }
      },
      {
        path: "warehouse/requestdetail/:requestId",
        name: "warehouseDetail",
        component: () =>
          import("../pages/approvalOfWarehouseRequests/requestDetail.vue")
      },
      {
        path: "agreement",
        name: "agreement",
        component: () => import("../pages/approvalOfTradeAgreement"),
        meta: {
          title: "Acordos Comerciais",
          caption: "Aprovação de Acordos Comerciais",
          icon: "mdi-file-document-edit-outline",
          onlyAdministrator: false,
          showHome: true,
          showDrawer: false
        }
      },
      {
        path: "agreement/agreementdetail/:agreementId",
        name: "agreementDetail",
        component: () =>
          import("../pages/approvalOfTradeAgreement/agreementDetail.vue")
      },
      {
        path: "contingencies",
        name: "contingencies",
        component: () => import("../pages/approvalOfContingencies"),
        meta: {
          title: "Aprovação de Contingências",
          caption: "Aprovação de Contingências",
          icon: "mdi-calculator-variant-outline",
          onlyAdministrator: false,
          showHome: true,
          showDrawer: false
        }
      },
      {
        path: "contingencies/contingenciedetail/:contingencieId",
        name: "contingencieDetail",
        component: () =>
          import("../pages/approvalOfContingencies/contingencieDetail.vue")
      },
      {
        path: "approvalFinancialTitle",
        name: "financialtitle",
        component: () => import("../pages/approvalOfFinancialTitle"),
        meta: {
          title: "Aprovação de Títulos Financeiros",
          caption: "Aprovação de Títulos Financeiros",
          icon: "mdi-finance",
          onlyAdministrator: false,
          showHome: true,
          showDrawer: false
        }
      },
      {
        path: "financialtitle/financialTitleDetail/:financialTitleId",
        name: "financialTitleDetail",
        component: () =>
          import("../pages/approvalOfFinancialTitle/financialTitleDetail.vue")
      },
      {
        path: "statusOrders",
        name: "Status dos Pedidos",
        component: () => import("../pages/statusOrders"),
        meta: {
          title: "Status Pedidos",
          caption: "Status dos Pedidos de Compra",
          icon: "mdi-list-status",
          onlyAdministrator: false,
          showHome: false,
          showDrawer: true
        }
      },
      {
        path: "/gfeApprovalTable",
        name: "gfe",
        component: () => import("../pages/gfeApprovalTable"),
        meta: {
          title: "Tabela GFE",
          caption: "Aprovação Tabela GFE",
          icon: "local_shipping",
          onlyAdministrator: false,
          showHome: true,
          showDrawer: false
        }
      },
      {
        path: "gfeApprovalTable/gfeDetail/:gfeId",
        name: "gfeDetail",
        component: () => import("../pages/gfeApprovalTable/gfeDetail.vue")
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Login",
      caption: "",
      icon: "",
      onlyAdministrator: false,
      showHome: false,
      showDrawer: false
    },
    component: () => import("../pages/login")
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Sair",
      caption: "Sair do sistema",
      icon: "exit_to_app",
      onlyAdministrator: false,
      showHome: false,
      showDrawer: true
    },
    component: () => import("../pages/logout")
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "*",
    component: () => import("../pages/Error404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path !== "/login" && !store.state.users.hasUserLoggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
