import firebaseApp from "@/firebase";

const db = firebaseApp.firestore();

export async function getStatusApproval({ commit }, payload) {
  var branch = String(payload[1].value).split("-");
  var companyCode = branch[0];
  var branchCode = branch[1];

  try {
    commit("shared/setLoadingMessage", "Atualizando Status das Aprovações", {
      root: true
    });

    const history = [];
    const snapshot = await db
      .collection("historyApproval")
      .where("collection", "==", "purchaseOrders")
      .where("codeNumber", "==", payload[0])
      .where("branchCode", "==", branchCode)
      .where("companyCode", "==", companyCode)
      .get();
    snapshot.forEach(doc => {
      history.push({ ...doc.data(), id: doc.id });
    });

    commit("setStatusApproval", history);
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar Histórico de Aprovação",
        color: "red",
        position: "top"
      },
      { root: true }
    );
  }
}

// export async function getWarehouseRequestById({ commit }, requestId) {
//   try {
//     commit("shared/setLoadingMessage", "Buscando Solicitações ao Armazém", {
//       root: true
//     });

//     const warehouse = await db
//       .collection("warehouseRequest")
//       .doc(requestId)
//       .get()
//       .then(doc => {
//         return { ...doc.data(), id: doc.id };
//       });
//     commit("shared/setLoadingMessage", null, { root: true });
//     return warehouse;
//   } catch (error) {
//     console.error(error);
//     commit("shared/setLoadingMessage", null, { root: true });

//     commit(
//       "shared/setNotification",
//       {
//         message: "Falha ao buscar Solicitação ao Armazém",
//         color: "red",
//         position: "top"
//       },
//       { root: true }
//     );
//     throw new Error(error.message);
//   }
// }

// export async function approvesRejectsWarehouseRequest({ commit }, payload) {
//   const { request, response } = payload;
//   const { id } = request;

//   commit("shared/setLoadingMessage", "Enviando resposta. Aguarde...", {
//     root: true
//   });
//   try {
//     const requestFb = await db
//       .collection("warehouseRequest")
//       .doc(id)
//       .get()
//       .then(doc => doc.data());
//     const { approvalType } = requestFb;
//     requestFb.status = "response_sent";
//     requestFb.response = response;

//     const batch = db.batch();
//     const setRef = db.collection("warehouseRequest").doc(id);
//     batch.update(setRef, {
//       status: "response_sent",
//       response
//     });

//     if (approvalType === "level") {
//       const querySnapshot = await db
//         .collection("warehouseRequest")
//         .where("branchCode", "==", requestFb.branchCode)
//         .where("requestNumber", "==", requestFb.requestNumber)
//         .where("level", "==", requestFb.level)
//         .where("status", "==", "waiting_analysis")
//         .get();

//       querySnapshot.forEach(doc => {
//         if (doc.id !== id) {
//           const updateRef = db.collection("warehouseRequest").doc(doc.id);
//           batch.update(updateRef, {
//             status: "analyzed_by_another_user",
//             response
//           });
//         }
//       });
//     }

//     await batch.commit();
//     commit("shared/setLoadingMessage", null, { root: true });
//   } catch (error) {
//     console.error(error);
//     commit("shared/setLoadingMessage", null, { root: true });
//     throw new Error("Falha no envio da repsosta. Tente novamente.");
//   }
// }
