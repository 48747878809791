import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/messaging";

const config = {
  apiKey: "AIzaSyBry-oADLlVvu27Cl0EijeNF2cepxi9D7Q",
  authDomain: "cicopal-aprova.firebaseapp.com",
  databaseURL: "https://cicopal-aprova.firebaseio.com",
  projectId: "cicopal-aprova",
  storageBucket: "cicopal-aprova.appspot.com",
  messagingSenderId: "319734931709",
  appId: "1:319734931709:web:c5255b282dce51bd611e94"
};
const firebaseApp = firebase.initializeApp(config);
firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase.firestore().enablePersistence();
export default firebaseApp;
