import firebaseApp from "@/firebase";
// import stringNow from "../../../helpers/stringNow";

const db = firebaseApp.firestore();

export async function getGFE({ commit, rootState }) {
  const { protheusUserCode } = rootState.users.loggedInUser;
  try {
    commit("shared/setLoadingMessage", "Atualizando Tabelas GFE", {
      root: true
    });

    const gfe = [];
    const snapshot = await db
      .collection("gfeApprovalTable")
      .where("protheusUser", "==", protheusUserCode)
      .where("status", "==", "waiting_analysis")
      .get();
    snapshot.forEach(doc => {
      gfe.push({ ...doc.data(), id: doc.id });
    });

    commit("setGFE", gfe);
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar Tabelas GFE",
        color: "red",
        position: "top"
      },
      { root: true }
    );
  }
}

export async function getGFEById({ commit }, gfeId) {
  try {
    commit("shared/setLoadingMessage", "Buscando Tabela GFE", {
      root: true
    });

    const gfe = await db
      .collection("gfeApprovalTable")
      .doc(gfeId)
      .get()
      .then(doc => {
        return { ...doc.data(), gfeId: doc.gfeId };
      });
    commit("shared/setLoadingMessage", null, { root: true });
    return gfe;
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar Tabela GFE",
        color: "red",
        position: "top"
      },
      { root: true }
    );
    throw new Error(error.message);
  }
}

export async function approvesRejectsGFETable({ commit }, payload) {
  const { gfe, response, reasonReject } = payload;
  const { id } = gfe;

  commit("shared/setLoadingMessage", "Enviando resposta. Aguarde...", {
    root: true
  });
  try {
    const gfeFb = await db
      .collection("gfeApprovalTable")
      .doc(id)
      .get()
      .then(doc => doc.data());
    const { approvalType } = gfeFb;
    gfeFb.status = "response_sent";
    gfeFb.response = response;

    // ##### PRECISO VALIDAR, MAS ESTE PROCESSO É REFERENTE AO HISTÓRICO DE APROVAÇÕES #####
    // let horario = new Date(Date.now());
    // let hour =
    //   String("0").repeat(2 - String(horario.getHours()).length) +
    //   horario.getHours();
    // let minute =
    //   String("0").repeat(2 - String(horario.getMinutes()).length) +
    //   horario.getMinutes();
    // let second =
    //   String("0").repeat(2 - String(horario.getSeconds()).length) +
    //   horario.getSeconds();

    // const hora = `${hour}:${minute}:${second}`;

    // const snapshot = await db
    //   .collection("historyApproval")
    //   .where("id", "==", id)
    //   .get();

    // snapshot.forEach(async doc => {
    //   const { protheusUserCode } = rootState.users.loggedInUser;
    //   const idHistory = doc.id;

    //   let hierarchy = doc.data().approvalHierarchy;
    //   let hierarchyIndex = hierarchy.findIndex(
    //     idx => idx.protheusUser === protheusUserCode
    //   );

    //   hierarchy[hierarchyIndex].approvalDate = stringNow();
    //   hierarchy[hierarchyIndex].approvalHour = hora;
    //   hierarchy[hierarchyIndex].status =
    //     response === "approved" ? "Aprovado" : "Reprovado";

    //   await db
    //     .collection("historyApproval")
    //     .doc(idHistory)
    //     .update({
    //       approvalHierarchy: [...hierarchy]
    //     });
    // });

    const batch = db.batch();
    const setRef = db.collection("gfeApprovalTable").doc(id);
    batch.update(setRef, {
      status: "response_sent",
      reasonRejection: reasonReject,
      response
    });

    if (approvalType === "level") {
      const querySnapshot = await db
        .collection("gfeApprovalTable")
        .where("branchCode", "==", gfeFb.branchCode)
        .where("negociation", "==", gfeFb.negociation)
        .where("level", "==", gfeFb.level)
        .where("status", "==", "waiting_analysis")
        .get();

      querySnapshot.forEach(doc => {
        if (doc.id !== id) {
          const updateRef = db.collection("gfeApprovalTable").doc(doc.id);
          batch.update(updateRef, {
            status: "analyzed_by_another_user",
            reasonRejection: reasonReject,
            response
          });
        }
      });
    }

    await batch.commit();
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });
    throw new Error("Falha no envio da resposta. Tente novamente.");
  }
}
